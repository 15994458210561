import React from "react"

import Layout from "../components/Layout"
import {graphql} from "gatsby"
import {NotFoundComponent} from "../components/NotFoundComponent";

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const NotFound = ({ data }: Props) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout>
        <NotFoundComponent />
    </Layout>
  )
};

export default NotFound

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;